import React from 'react';
import tw from 'twin.macro';
import { useSelector } from 'react-redux';
import { FixedSizeList } from 'react-window';
import { selectSavedTracksInReverseChronologicalOrder } from '../selectors';
import { Track } from '../types';
import TrackItem from './TrackItem';

const Container = tw.div`flex flex-col p-10 h-full`;

const ListTitle = tw.p`text-2xl text-purple-500 p-2`;

const TrackList = () => {
  const tracks: Track[] = useSelector(selectSavedTracksInReverseChronologicalOrder);

  return (
    <Container>
      <ListTitle>Saved Tracks</ListTitle>

      {/* // @ts-ignore */}
      <FixedSizeList
        height={window.innerHeight - 100}
        itemCount={tracks.length}
        itemSize={35}
        width="100%"
      >
        {({ index, style }) => (
          <div style={style}>
            <TrackItem track={tracks[index]} key={index} />
          </div>
        )}
      </FixedSizeList>
    </Container>
  );
};

export default TrackList;
