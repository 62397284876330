import { map } from 'lodash';
import { addTracks } from './actions';
import { TrackActionTypes } from './types';
import { apiFetch } from '../util/fetch';
import { sleepMs } from '../util/sleep';

const PAGE_SIZE: number = 50;

const BASE_URL: string = `https://api.spotify.com/v1/me/tracks?offset=0&limit=${PAGE_SIZE}`;

export const fetchAllLikedTracks = (dispatch: ((action: TrackActionTypes) => void)) => {
  const func = async () => {
    let url: string | null = BASE_URL;
    while (url != null) {
      const response: SpotifyApi.UsersSavedTracksResponse = await apiFetch(url) as SpotifyApi.UsersSavedTracksResponse;
      dispatch(addTracks(map(response.items, (item) => ({ track: item.track, savedAt: new Date(item.added_at) }))));
      url = response.next;
      await sleepMs(50);
    }
  };
  func();
};
