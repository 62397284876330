import React from 'react';
import { GlobalStyles } from 'twin.macro';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { handleCallback, redirectToLogin, isLoggedIn } from './util/auth';
import newStore from './store';
import Base from './Base';

const { store, persistor } = newStore();
declare global {
  interface Window {
    store:any;
  }
}
window.store = store;

const App = () => {
  if (!isLoggedIn()) {
    if (window.location.pathname === '/callback') {
      handleCallback();
      return null;
    }
    redirectToLogin();
    return null;
  }

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <GlobalStyles />
        <Base />
      </PersistGate>
    </Provider>
  );
};

export default App;
