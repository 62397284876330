// @ts-ignore
const CLIENT_ID = window.SPOTIFY_CLIENT_ID;

const API_TOKEN_KEY: string = 'api_token';

function randomBytes(size: number) {
  return crypto.getRandomValues(new Uint8Array(size));
}

function base64url(bytes: any): string {
  return btoa(String.fromCharCode(...bytes))
    .replace(/=/g, '')
    .replace(/\+/g, '-')
    .replace(/\//g, '_');
}

async function generateCodeChallenge(codeVerifier: string) {
  const codeVerifierBytes = new TextEncoder().encode(codeVerifier);
  const hashBuffer = await crypto.subtle.digest('SHA-256', codeVerifierBytes);
  return base64url(new Uint8Array(hashBuffer));
}

export async function redirectToLogin() {
  // https://tools.ietf.org/html/rfc7636#section-4.1
  const codeVerifier = base64url(randomBytes(96));
  const state = base64url(randomBytes(96));

  const scope = [
    'user-read-recently-played',
    'user-top-read',
    'user-read-playback-state',
    'user-modify-playback-state',
    'playlist-read-private',
    'playlist-read-collaborative',
    'user-follow-read',
    'user-library-read',
    'streaming',
    'user-read-email',
    'user-read-private',
    'user-read-playback-state',
    'user-modify-playback-state',
  ].join(' ');
  const queryString: string = new URLSearchParams({
    client_id: CLIENT_ID,
    response_type: 'code',
    redirect_uri: `${window.location.origin}/callback`,
    code_challenge_method: 'S256',
    code_challenge: `${await generateCodeChallenge(codeVerifier)}`,
    state,
    scope,
  }).toString();

  sessionStorage.setItem('codeVerifier', codeVerifier);
  sessionStorage.setItem('state', state);
  window.location.href = `https://accounts.spotify.com/authorize?${queryString}`;
}
async function fetchJSON(url: string, init: any) {
  const response = await fetch(url, init);
  const body = await response.json();
  if (!response.ok) {
    return null;
  }
  return body;
}

async function createAccessToken(params: any) {
  const response = await fetchJSON('https://accounts.spotify.com/api/token', {
    method: 'POST',
    body: new URLSearchParams({
      client_id: CLIENT_ID,
      ...params,
    }),
  });

  // const accessToken = response.access_token;
  if (!response) {
    window.location.reload();
  }
  const expiresAt = Date.now() + 1000 * response.expires_in;

  localStorage.setItem(
    API_TOKEN_KEY,
    JSON.stringify({ ...response, expiresAt }),
  );
}

export async function handleCallback() {
  const codeVerifier = sessionStorage.getItem('codeVerifier');
  const state = sessionStorage.getItem('state');

  const params = new URLSearchParams(window.location.search);

  if (params.has('error')) {
    throw new Error(params.get('error') as any);
  } else if (!params.has('state')) {
    throw new Error('State missing from response');
  } else if (params.get('state') !== state) {
    throw new Error('State mismatch');
  } else if (!params.has('code')) {
    throw new Error('Code missing from response');
  }

  await createAccessToken({
    grant_type: 'authorization_code',
    code: params.get('code'),
    redirect_uri: `${window.location.origin}/callback`,
    code_verifier: codeVerifier,
  });

  window.location.href = `${window.location.origin}`;
}

export async function refreshToken(token: string) {
  await createAccessToken({
    grant_type: 'refresh_token',
    refresh_token: token,
  });
}

export const getApiToken = (): string | null => {
  // @ts-ignore
  let authData = JSON.parse(localStorage.getItem(API_TOKEN_KEY));
  if (authData == null) {
    return null;
  }

  if (authData.expires_at < Date.now()) {
    refreshToken(authData.refresh_token);
  }
  // @ts-ignore
  authData = JSON.parse(localStorage.getItem(API_TOKEN_KEY));

  return authData.access_token;
};

export const isLoggedIn = (): boolean => getApiToken() != null;
