export const ADD_TRACKS = 'tracks/ADD_TRACKS';
export const ADD_AUDIO_FEATURES = 'tracks/ADD_AUDIO_FEATURES';

export interface AddTracksAction {
  type: typeof ADD_TRACKS
  payload: SavedTrack[]
}

export interface AddAudioFeaturesAction {
  type: typeof ADD_AUDIO_FEATURES
  payload: SpotifyApi.AudioFeaturesObject[]
}

export type TrackActionTypes = AddTracksAction | AddAudioFeaturesAction;

export interface TracksState {
  allTracks: TrackList
}

export interface SavedTrack {
  track: SpotifyApi.TrackObjectFull
  savedAt: Date | null
}

export interface TrackList {
  [key: string]: Track
}

export interface Track {
  track: SpotifyApi.TrackObjectFull
  audioFeatures: SpotifyApi.AudioFeaturesObject
  savedAt: Date | null
}
