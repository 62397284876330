import { PlayerActionTypes, SET_PLAYER_QUEUE, SET_PLAYING } from './types';

export function setPlayerQueue(queue: string[]): PlayerActionTypes {
  return {
    type: SET_PLAYER_QUEUE,
    payload: queue,
  };
}

export function setPlaying(playing: boolean): PlayerActionTypes {
  return {
    type: SET_PLAYING,
    payload: playing,
  };
}
