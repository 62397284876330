import { reduce } from 'lodash';
import {
  ADD_AUDIO_FEATURES, ADD_TRACKS, Track, TrackActionTypes, TracksState,
} from './types';

const initialState: TracksState = {
  allTracks: {},
};

export function tracksReducer(
  state = initialState,
  action: TrackActionTypes,
): TracksState {
  switch (action.type) {
    // @ts-ignore
    case ADD_TRACKS:
      return {
        ...state,
        allTracks: reduce(action.payload, (result, value) => {
          result[value.track.id] = {
            ...result[value.track.id],
            track: value.track,
            savedAt: value.savedAt,
          } as Track;
          return result;
        }, { ...state.allTracks }),
      };

    case ADD_AUDIO_FEATURES:
      return {
        ...state,
        allTracks: reduce(action.payload, (result, value) => {
          if (!result[value.id]) {
            return result;
          }
          result[value.id] = {
            ...result[value.id],
            audioFeatures: value,
          } as Track;
          return result;
        }, { ...state.allTracks }),
      };

    default:
      return state;
  }
}
