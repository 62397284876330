import { createSelector } from 'reselect';
import {
  filter, sortBy, reverse, has,
} from 'lodash';
import { Track, TrackList, TracksState } from './types';
import { RootState } from '../store';

const selectTrackState = (state: RootState): TracksState => state.tracks;

export const selectAllTracks = createSelector(
  selectTrackState,
  (trackState): TrackList => trackState.allTracks,
);

export const selectSavedTracks = createSelector(
  selectAllTracks,
  (tracks): Track[] => filter(tracks, (track) => track.savedAt !== null),
);

export const selectSavedTracksInChronologicalOrder = createSelector(
  selectSavedTracks,
  (tracks): Track[] => sortBy(tracks, [(track) => track.savedAt]),
);

export const selectSavedTracksInReverseChronologicalOrder = createSelector(
  selectSavedTracksInChronologicalOrder,
  (tracks): Track[] => reverse(tracks),
);

export const selectTrackHasAudioFeatures = createSelector(
  selectAllTracks,
  (state: any, trackId: any): string => trackId,
  (trackList: TrackList, trackId: string): boolean => has(trackList, [trackId, 'audioFeatures']),
);
