import {
  applyMiddleware, combineReducers, compose, createStore, Store,
} from 'redux';
import { reduxBatch } from '@manaflair/redux-batch';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';
import * as localforage from 'localforage';
import { tracksReducer } from './tracks/reducer';
import { playerReducer } from './player/reducer';
import { onAddTrackSaga } from './tracks/sagas';

const persistConfig = {
  key: 'root',
  storage: localforage,
};

const rootReducer = persistReducer(persistConfig, combineReducers({
  tracks: tracksReducer,
  player: playerReducer,
}));

export type RootState = ReturnType<typeof rootReducer>;

const sagaMiddleware = createSagaMiddleware();

const sagas = [
  onAddTrackSaga,
];

const newStore = () => {
  const store = createStore(rootReducer, compose(reduxBatch, applyMiddleware(sagaMiddleware), reduxBatch));
  const persistor = persistStore(store as unknown as Store);
  sagas.forEach((saga: any) => sagaMiddleware.run(saga));
  return { store, persistor };
};

export default newStore;
