import React, { useEffect } from 'react';
import tw from 'twin.macro';
import { useDispatch, useSelector } from 'react-redux';
import useEventListener from '@use-it/event-listener';
import { Player } from './player/components/Player';
import newStore from './store';
import { fetchAllLikedTracks } from './tracks/effects';
import TrackList from './tracks/components/TrackList';
import { selectIsPlaying } from './player/selectors';
import { setPlaying } from './player/actions';

const PageContainer = tw.div`flex flex-col bg-gray-darkest h-screen w-screen justify-between`;

const Container = tw.div`flex flex-col h-full w-full overflow-y-auto mb-auto`;

const store = newStore();
declare global {
  interface Window {
    store:any;
  }
}
window.store = store;

const Base = () => {
  const dispatch = useDispatch();
  const isPlaying = useSelector(selectIsPlaying);

  useEffect(() => {
    fetchAllLikedTracks(dispatch);
  }, [dispatch]);

  useEventListener('keydown', (event: KeyboardEvent) => {
    if (event.key === ' ' && event.target === document.body) {
      event.preventDefault();
      dispatch(setPlaying(!isPlaying));
    }
  });

  return (
    <PageContainer>
      <Container>
        <TrackList />
      </Container>
      <Player />
    </PageContainer>
  );
};

export default Base;
