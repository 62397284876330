import React from 'react';
import tw, { styled } from 'twin.macro';
import { useDispatch, useSelector } from 'react-redux';
import { Track } from '../types';
import { setPlayerQueue, setPlaying } from '../../player/actions';
import { selectCurrentTrackId } from '../../player/selectors';
import { msToMinutesAndSeconds } from '../../util/time';

const Album = tw.img`w-8 h-full`;
const RowContainer = tw.div`flex flex-row hover:bg-gray-darker`;
const Divider = tw.div`bg-purple-500 opacity-40 h-px`;

type TitleProps = { highlighted: boolean };

const ColumnText = styled.p<TitleProps>`
  ${tw`flex flex-col justify-center text-white mx-6 truncate cursor-pointer hover:text-pink-500`} 
  ${({ highlighted }) => highlighted && tw`text-purple-500`}
`;

const TrackText = tw(ColumnText)`w-6/12`;
const ArtistText = tw(ColumnText)`w-5/12`;
const DurationText = tw(ColumnText)`w-1/12`;

type TrackProps = { track: Track };

const TrackItem = ({ track }: TrackProps) => {
  const dispatch = useDispatch();
  const currentTrackId = useSelector(selectCurrentTrackId);
  const {
    id, name, artists, duration_ms: durationMs, album,
  } = track.track;
  const artistsString = artists.map((artist) => artist.name).join(', ');
  const { images = [{ url: '' }] } = album;
  const { url } = images[2];
  const highlighted:boolean = id === currentTrackId;
  return (
    <>
      <Divider />
      <RowContainer>
        <Album src={url} />
        <TrackText
          highlighted={highlighted}
          onClick={() => {
            dispatch(setPlayerQueue([id]));
            dispatch(setPlaying(true));
          }}
        >
          {name}
        </TrackText>
        <ArtistText highlighted={highlighted}>
          {artistsString}
        </ArtistText>
        <DurationText highlighted={highlighted}>
          {msToMinutesAndSeconds(durationMs)}
        </DurationText>
      </RowContainer>
    </>
  );
};

export default TrackItem;
