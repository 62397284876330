import {
  ADD_AUDIO_FEATURES, ADD_TRACKS, SavedTrack, TrackActionTypes,
} from './types';

export function addTracks(tracks: SavedTrack[]): TrackActionTypes {
  return {
    type: ADD_TRACKS,
    payload: tracks,
  };
}

export function addAudioFeatures(audioFeatures: SpotifyApi.AudioFeaturesObject[]): TrackActionTypes {
  return {
    type: ADD_AUDIO_FEATURES,
    payload: audioFeatures,
  };
}
