export const SET_PLAYER_QUEUE = 'player/SET_PLAYER_QUEUE';
export const SET_PLAYING = 'player/UPDATE_PLAYING';

interface SetPlayerQueueAction {
  type: typeof SET_PLAYER_QUEUE
  payload: string[]
}

interface SetPlayingAction {
  type: typeof SET_PLAYING
  payload: boolean
}

export type PlayerActionTypes = SetPlayerQueueAction | SetPlayingAction;

export interface PlayerState {
  queue: string[]
  playing: boolean
}
