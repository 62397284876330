import { put, select, takeEvery } from 'redux-saga/effects';
import { filter, join, map } from 'lodash';
import { ADD_TRACKS, AddTracksAction, SavedTrack } from './types';
import { addAudioFeatures } from './actions';
import { selectTrackHasAudioFeatures } from './selectors';
import { apiFetch } from '../util/fetch';

const BASE_URL: string = 'https://api.spotify.com/v1/audio-features/';

function* fetchAudioFeaturesIfNotCached(action: AddTracksAction) {
  const tracks = action.payload;
  // @ts-ignore
  const cached = yield tracks.map((savedTrack) => select(selectTrackHasAudioFeatures, savedTrack.track.id));
  const uncachedTracks: SavedTrack[] = filter(tracks, (_, i: number): boolean => cached[i]);
  if (uncachedTracks.length === 0) {
    return;
  }
  const trackIds = map(uncachedTracks, (savedTrack) => savedTrack.track.id);
  const url = `${BASE_URL}?ids=${join(trackIds, ',')}`;
  const response: SpotifyApi.MultipleAudioFeaturesResponse = yield apiFetch(url) as unknown as SpotifyApi.MultipleAudioFeaturesResponse;

  yield put(addAudioFeatures(response.audio_features));
}

export function* onAddTrackSaga() {
  yield takeEvery(ADD_TRACKS, fetchAudioFeaturesIfNotCached);
}
