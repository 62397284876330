import {
  PlayerActionTypes, PlayerState, SET_PLAYER_QUEUE, SET_PLAYING,
} from './types';

const initialState: PlayerState = {
  queue: ['3OCxOUUH3FUf8xk0RuWADJ'],
  playing: false,
};

export function playerReducer(
  state = initialState,
  action: PlayerActionTypes,
): PlayerState {
  switch (action.type) {
    case SET_PLAYER_QUEUE:
      return {
        ...state,
        queue: action.payload,
      };

    case SET_PLAYING:
      return {
        ...state,
        playing: action.payload,
      };

    default:
      return state;
  }
}
