import { getApiToken } from './auth';
import { sleepSeconds } from './sleep';

const STATUS_OK: number = 200;
const STATUS_TOO_MANY_REQUESTS: number = 429;

export const apiFetch = async (url: string) => {
  while (true) {
    const response = await fetch(url,
      {
        method: 'GET',
        headers: { Authorization: `Bearer ${getApiToken()}` },
      }).then((res) => res);

    switch (response.status) {
      case STATUS_OK:
        return response.json();
      case STATUS_TOO_MANY_REQUESTS:
        await sleepSeconds(Number(response.headers.get('Retry-After')));
        continue;
      default:
        return null;
    }
  }
};
