import * as React from 'react';
import SpotifyPlayer from 'react-spotify-web-playback';
import tw, { theme } from 'twin.macro';
import { useDispatch, useSelector } from 'react-redux';
import { setPlayerQueue, setPlaying } from '../actions';
import { getApiToken } from '../../util/auth';
import { selectIsPlaying, selectPlayerQueueURIs } from '../selectors';

const Container = tw.div`flex h-auto w-full`;

export const Player = () => {
  const dispatch = useDispatch();
  const queue = useSelector(selectPlayerQueueURIs);
  const isPlaying = useSelector(selectIsPlaying);

  const apiToken: string | null = getApiToken();
  if (!apiToken) {
    return null;
  }
  return (
    <Container>
      <SpotifyPlayer
        token={`${getApiToken()}`}
        styles={{
          height: '100px',
          activeColor: theme('colors.white'),
          bgColor: theme('colors.gray.darkest'),
          color: theme('colors.white'),
          loaderColor: theme('colors.white'),
          sliderColor: theme('colors.purple'),
          sliderTrackColor: theme('colors.white'),
          sliderHandleColor: theme('colors.white'),
          trackArtistColor: theme('colors.purple'),
          trackNameColor: theme('colors.white'),
        }}
        callback={((state) => {
          dispatch(setPlaying(state.isPlaying));
          if (state.track.id !== '') {
            const nextTrackIds = state.nextTracks.map((track) => track.id);
            dispatch(setPlayerQueue([state.track.id, ...nextTrackIds]));
          }
        })}
        uris={queue}
        play={isPlaying}
        autoPlay={false}
      />
    </Container>
  );
};
