import { createSelector } from 'reselect';
import { map, first } from 'lodash';
import { RootState } from '../store';
import { PlayerState } from './types';

const selectPlayerState = (state: RootState): PlayerState => state.player;

export const selectIsPlaying = createSelector(
  selectPlayerState,
  (playerState): boolean => playerState.playing,
);

export const selectPlayerQueue = createSelector(
  selectPlayerState,
  (playerState): string[] => playerState.queue,
);

export const selectPlayerQueueURIs = createSelector(
  selectPlayerQueue,
  (queue): string[] => map(queue, (id) => `spotify:track:${id}`),
);

export const selectCurrentTrackId = createSelector(
  selectPlayerQueue,
  (queue): string | undefined => first(queue),
);
